/*******************************************************************************
 *
 *
 * (C) Copyright 2023 Autodesk, Inc. All rights reserved.
 *
 *                      ****  CONFIDENTIAL MATERIAL  ****
 *
 * The information contained herein is confidential, proprietary to
 * Autodesk, Inc., and considered a trade secret.  Use of this information
 * by anyone other than authorized employees of Autodesk, Inc. is granted
 * only under a written nondisclosure agreement, expressly prescribing the
 * the scope and manner of such use.
 *
 ******************************************************************************/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
